@import 'styles/fonts.scss';

$unit: 8px;

$call-height: 64px;
$call-margin: 20px;
$call-width: 350px;

// TODO(ibash) old color -- expect these to be removed in a redesign
$grey: #c4c4c4;
$yellow: #ffc46a;

$green-system: rgba(52, 199, 89, 1);
$red-system: rgba(255, 59, 48, 1);
$grey-dim-system: rgaba(235, 235, 245, 1);

$black-03: rgba(0, 0, 0, 0.03);
$black-10: rgba(0, 0, 0, 0.1);
$black-15: rgba(0, 0, 0, 0.15);
$black-20: rgba(0, 0, 0, 0.2);
$black-30: rgba(0, 0, 0, 0.3);
$black-40: rgba(0, 0, 0, 0.4);
$black-75: rgba(0, 0, 0, 0.75);
$black-95: rgba(0, 0, 0, 0.95);
$black: rgba(0, 0, 0, 1);
$white: rgba(255, 255, 255, 1);

// primary theme color
$blue-20: rgba(204, 212, 235, 0.2);
$blue: rgba(204, 212, 235, 1);

// secondary theme color
$green: rgba(161, 204, 179, 1);

// TODO(ibash) these colors are not well-named...
$green-1: rgba(159, 217, 183, 1);
$green-2: rgba(176, 226, 197, 1);
$grey-2: rgba(130, 130, 130, 1);
$grey-3: rgba(120, 120, 128, 0.16);
$grey-4: rgba(242, 242, 242, 1);

$font-weight-ultralight: 100;
$font-weight-thin: 200;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-extrabold: 800;
$font-weight-heavy: 900;
$font-weight-black: 950;

// ref: https://github.com/twbs/bootstrap/blob/5ec6400bd01057ab1d95285b5744dcf942192ad6/scss/_variables.scss#L396
$font-family-sans-serif: system-ui, -apple-system, 'Segoe UI', Roboto,
  'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !default;

@mixin font-sans($size: false, $colour: false, $weight: false, $lh: false) {
  font-family: $font-family-sans-serif;
  @if $size {
    font-size: $size;
  }
  @if $colour {
    color: $colour;
  }
  @if $weight {
    font-weight: $weight;
  }
  @if $lh {
    line-height: $lh;
  }
}

@mixin font-regular {
  @include font-sans(14px, $black, 400, 20px);
}

@mixin font-title {
  @include font-sans(16px, $black, 500, 20px);
}

@mixin font-subheader {
  @include font-sans(14px, $grey-2, 500, 14px);
}

@mixin font-button {
  @include font-sans(12px, $green-1, 600, 20px);
}
